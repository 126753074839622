<template>
  <div>
    <!--    <van-nav-bar-->
    <!--      title="本市影院本年度票房排行榜"-->
    <!--      left-text="返回"-->
    <!--      left-arrow-->
    <!--      @click-left="toTopPage()"-->
    <!--    >-->
    <!--    </van-nav-bar>-->
    <div class="table" v-if="dataList.length > 0">
      <div class="table-header">
        <div>名次</div>
        <div>影院</div>
        <div>票房(万)</div>
        <div>服务费(万)</div>
      </div>
      <div class="table-content">
        <div
          class="table-content-item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div>
            <img
              src="./../../../assets/icon/huangguan_1.svg"
              v-if="item.index === 1"
              style="width: 30px"
              alt=""
              srcset=""
            />
            <img
              src="./../../../assets/icon/huangguan_2.svg"
              v-else-if="item.index === 2"
              style="width: 30px"
              alt=""
              srcset=""
            />
            <img
              src="./../../../assets/icon/huangguan.svg"
              v-else-if="item.index === 3"
              style="width: 30px"
              alt=""
              srcset=""
            />
            <span v-else>{{ item.index }}</span>
          </div>
          <div>{{ item.cinemaName }}</div>
          <div>{{ item.boxOffice }}</div>
          <div>{{ item.serviceCharge }}</div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无数据" />
    <div class="desc">
      说明:本页面数据自2022年1月至2022年9月10日累计票房，按照票房（不包含服务费）金额从大到小排名。
      <br />
      更新于:{{ updateTime || '无' }}
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'yearBoxOffice',
  data() {
    return {
      cinemaId: '',
      updateTime: '2018-01-01',
      dataList: [],
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101602","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { data, Message, Success } = r
        if (Success) {
          this.dataList = data.rankDatas.map((item) => {
            return {
              index: item.CityRank,
              cinemaName: item.CinemaName,
              boxOffice: `${item.BoxOfficeNoService}`,
              serviceCharge: `${item.ServiceFee}`,
            }
          })
          this.updateTime = data.UpdateDate
          console.log({ 年度票房排行榜: r })
        } else {
          Toast(Message)
        }
      })
    },
    toTopPage() {
      window.history.back()
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #3d8bff;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
.table {
  font-size: 0.4rem;
  &-header {
    background: #f2f2f2;
    display: flex;
    height: 1rem;
    align-items: center;
    > div {
      flex: 1;
      text-align: center;
      &:nth-child(2) {
        flex: 2;
      }
      &:nth-child(1) {
        flex: 0.6;
      }
    }
  }
  &-content {
    &-item {
      &:nth-child(2n) {
        background-color: #f6f7f8;
      }
      display: flex;
      height: 1.2rem;
      align-items: center;
      > div {
        flex: 1;
        text-align: center;
        &:nth-child(2) {
          flex: 2;
        }
        &:nth-child(1) {
          flex: 0.6;
        }
      }
    }
  }
}
.desc {
  padding: 0.3rem;
  font-size: 13px;
  color: rgba(93, 93, 96, 0.44);
}
.red {
  color: #ff5c5c;
}
.green {
  color: #5dea27;
}
</style>
